import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import '../../i18n';

interface MenuItemProps {
    label: string;
    link: string;
    submenu?: { label: string; link: string; }[];
}

export default function MenuItem({ label, link, submenu }: MenuItemProps) {
    const { t } = useTranslation();

    const location = useLocation();
    const pathname = location.pathname;
    const isActive = location.pathname === link;

    if (submenu) {
        return (
            <li className="nav-item" key={label}>
                <Link
                    to={link}
                    className="nav-link"
                    onClick={(e) => e.preventDefault()}
                >
                    {t(label)} <i className="fas fa-chevron-down"></i>
                </Link>

                <ul className="dropdown-menu">
                    {submenu.map((subItem) => {
                        const isActive = pathname === subItem.link;
                        return (
                            <li className="nav-item" key={subItem.label}>
                                <Link
                                    to={subItem.link}
                                    className={`nav-link ${isActive ? "active" : ""}`}
                                >
                                    {t(subItem.label)}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </li>
        );
    }

    return (
        <li className="nav-item" key={label}>
            <Link to={link} className={`nav-link ${isActive ? "active" : ""}`}>
                {t(label)}
            </Link>
        </li>
    );
}