/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MenuItem from "./MenuItem";
import { useTranslation } from "react-i18next";
import "../../i18n";

const menus = [
  {
    label: "home_navbar",
    link: "/",
  },
  {
    label: "about_us_navbar",
    link: "/about-us",
  },
  {
    label: "services_navbar",
    link: "/services",
  },
  {
    label: "contact_us_navbar",
    link: "/contact-us",
  },
];

export default function Navbar() {
  const [menu, setMenu] = useState(true);
  const { t, i18n } = useTranslation();
  const toggleNavbar = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId?.classList.add("is-sticky");
      } else {
        elementId?.classList.remove("is-sticky");
      }
    });
  });

  const classOne = menu
    ? "collapse navbar-collapse mean-menu"
    : "collapse navbar-collapse show";
  const classTwo = menu
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  const changeLanguage = (lang: any) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div id="navbar" className="navbar-area">
      <div className="main-nav">
        <div className="container">
          <nav className="navbar navbar-expand-md navbar-light">
            <Link to="/" className="navbar-brand">
              <img
                alt="logo-white"
                loading="lazy"
                className="white-logo"
                style={{ width: 200, height: "auto", color: "transparent" }}
                src="images/logo-white.png"
              />

              <img
                alt="logo-black"
                loading="lazy"
                className="black-logo"
                style={{ width: 200, height: "auto", color: "transparent" }}
                src="images/logo-black.png"
              />
            </Link>

            <button
              onClick={toggleNavbar}
              className={classTwo}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar top-bar"></span>
              <span className="icon-bar middle-bar"></span>
              <span className="icon-bar bottom-bar"></span>
            </button>

            <div className={classOne} id="navbarSupportedContent">
              <ul className="navbar-nav m-auto">
                {menus.map((menuItem) => (
                  <MenuItem key={menuItem.label} {...menuItem} />
                ))}
              </ul>

              <div className="btn-languages">
                <button
                  className="btn-languages-flag"
                  onClick={() => changeLanguage("en")}
                >
                  <img
                    src="/flags/US.svg"
                    alt=""
                    style={{ width: 25, height: 25 }}
                  />
                </button>
                <button
                  className="btn-languages-flag"
                  onClick={() => changeLanguage("cn")}
                >
                  <img
                    src="/flags/CN.svg"
                    alt=""
                    style={{ width: 25, height: 25 }}
                  />
                </button>
                <button
                  className="btn-languages-flag"
                  onClick={() => changeLanguage("ptBr")}
                >
                  <img
                    src="/flags/BR.svg"
                    alt=""
                    style={{ width: 25, height: 25 }}
                  />
                </button>
              </div>

              <div className="others-options">
                <Link
                  to="https://api.whatsapp.com/send/?phone=5511971832670&text=textourl&type=phone_number&app_absent=0"
                  className="default-btn"
                  target="_blank"
                >
                  {t("budget_navbar")}
                  <span></span>
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
