import PageBanner from "../../components/Common/PageBanner";
import Footer from "../../components/Layouts/Footer";
import Navbar from "../../components/Layouts/Navbar";
import FeaturedService from "../../components/Services/FeaturedService";
import ServicesContent from "../../components/Services/ServicesContent";
import { useTranslation } from "react-i18next";
import "../../i18n";

export default function Services() {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />

      <PageBanner
        pageTitle={t("pageTitle_services")}
        breadcrumbTextOne={t("breadcrumbTextOne_services")}
        breadcrumbTextTwo={t("breadcrumbTextTwo_services")}
        breadcrumbUrl="/"
        bgImage="images/page-title-bg-4.jpg"
      />

      <FeaturedService />

      <ServicesContent />

      <Footer />
    </>
  );
}
