import { useTranslation } from "react-i18next";
import "../../i18n";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import { Typography } from "@mui/material";
import DateRangeIcon from '@mui/icons-material/DateRange';

export default function PropostaContent() {
  const { t } = useTranslation();

  const timelineItems = [
    {
      event: t("item0_proposta"),
      description: t("item0_description_proposta"),
    },
    {
      date: "30 dias",
      event: t("item1_proposta"),
      description: t("item1_description_proposta"),
    },
    {
      date: "30 dias",
      event: t("item2_proposta"),
      description: t("item2_description_proposta"),
    },
    {
      date: "30 dias",
      event: t("item3_proposta"),
      description: t("item3_description_proposta"),
    },
    {
      date: "30 dias",
      event: t("item4_proposta"),
      description: t("item4_description_proposta"),
    },
    {
      date: "30 dias",
      event: t("item5_proposta"),
      description: t("item5_description_proposta"),
    },
    {
      date: "30 dias",
      event: t("item6_proposta"),
      description: t("item6_description_proposta"),
    },
    {
      date: "30 dias",
      event: t("item7_proposta"),
      description: t("item7_description_proposta"),
    },
    {
      date: "30 dias",
      event: t("item8_proposta"),
      description: t("item8_description_proposta"),
    },
    {
      date: "30 dias",
      event: t("item9_proposta"),
      description: t("item9_description_proposta"),
    },
    {
      date: "30 dias",
      event: t("item10_proposta"),
      description: t("item10_description_proposta"),
    },
    {
      date: "30 dias",
      event: t("item11_proposta"),
      description: t("item11_description_proposta"),
    },
  ];

  return (
    <>
      <div className="about-section" style={{ padding: '50px 0 50px 0' }}>
        <div className="container">
          <div className="align-items-center">
            <div className="col-lg-12">
              <div className="about-content text-center">
                <h2>{t("title_proposta")}</h2>
                <div style={{ fontSize: '18px', lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: t("description_proposta") }} />
                <h3 className="text-center mt-5">{t("phase1_proposta")}</h3>
                <Timeline position="alternate-reverse">
                  {timelineItems.map((item, index) => (
                    <TimelineItem key={index}>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                          {item.event}
                        </Typography>
                        <Typography>{item.description}</Typography>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
                <div className="row mt-4">
                <div className="col md-12">
                    <h2>{t("term_proposta_unificacao")}</h2>
                    <p style={{ fontSize: '30px' }}><DateRangeIcon /> {t("deadline_days_proposta_unificacao")}</p>
                  </div>
                  <div className="col-md-12 mt-5">
                    <h2>{t("term_proposta")}</h2>
                    <p style={{ fontSize: '30px' }}><DateRangeIcon /> {t("deadline_days_proposta")}</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
