import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translation files
import enTranslation from './locales/en/translation.json';
import cnTranslation from './locales/cn/translation.json';
import ptBrTranslation from './locales/ptBr/translation.json'

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      cn: {
        translation: cnTranslation,
      },
      ptBr: {
        translation: ptBrTranslation
      }
    },
    lng: 'ptBt', // Default language
    fallbackLng: 'ptBr',
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
