/* eslint-disable jsx-a11y/img-redundant-alt */
import { useTranslation } from "react-i18next";
import "../../i18n";

export default function Customers() {
  const { t } = useTranslation();

  const partners = [
    { src: "/images/partner/bib.png", delay: 50 },
    { src: "/images/partner/construcap.png", delay: 100 },
    { src: "/images/partner/econdos.png", delay: 150 },
    { src: "/images/partner/lanup.png", delay: 200 },
    { src: "/images/partner/joy.png", delay: 250 },
    { src: "/images/partner/mcdonald.png", delay: 300 },
    { src: "/images/partner/mania-churrasco.png", delay: 350 },
    { src: "/images/partner/tennessee.png", delay: 400 },
    { src: "/images/partner/rwtech.png", delay: 450 },
  ];

  return (
    <>
      <div className="partner-section pt-100 pb-70">
        <div className="container">
          <div className="partner-title">
            <span>{t("title_customers")}</span>
            <h2>{t("our_partnerships_customers")}</h2>
          </div>

          <div className="partner-list">
            {partners.map((partner, index) => (
              <div
                key={index}
                className="partner-item"
                data-aos="zoom-it"
                data-aos-delay={partner.delay}
                data-aos-duration="1200"
              >
                <img
                  src={partner.src}
                  alt="Partner Logo"
                  width={100}
                  height={100}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="partner-shape">
          <img
            src="/images/shape/partnar-shape-1.png"
            alt="main-image"
            width={364}
            height={492}
          />
        </div>
        <div className="partner-shape-img1">
          <img
            src="/images/shape/partnar-shape-2.png"
            alt="image"
            width={277}
            height={492}
          />
        </div>
      </div>
    </>
  );
}
