import Navbar from "../../components/Layouts/Navbar";
import PageBanner from "../../components/Common/PageBanner";
import AboutUsContent from "../../components/AboutUs/AboutUsContent";
import Testimonial from "../../components/Common/Testimonial";
import Customers from "../../components/Common/Customers";
import Footer from "../../components/Layouts/Footer";
import { useTranslation } from 'react-i18next';
import '../../i18n';

export default function AboutUs() {

  const { t } = useTranslation();

  return (
    <>
      <Navbar />

      <PageBanner
        pageTitle={t('pageTitle_about_us')}
        breadcrumbTextOne={t('breadcrumbTextOne_about_us')}
        breadcrumbTextTwo={t('breadcrumbTextTwo_about_us')}
        breadcrumbUrl="/"
        bgImage="/images/page-title-bg.jpg"
      />

      <AboutUsContent />

      <Testimonial />

      <Customers />

      <Footer />
    </>
  );
}
