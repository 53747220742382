import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./pages/styles/bootstrap.min.css";
import "./pages/styles/flaticon.css";
import "./pages/styles/fontawesome.min.css";
import "react-tabs/style/react-tabs.css";
import "swiper/css";
import "swiper/css/bundle";

import "./pages/styles/style.css";
import "./pages/styles/responsive.css";

import "./globals.css";
import Home from "./pages/home/home";
import AboutUs from "./pages/about-us/about-us";
import Services from "./pages/services/services";
import GoTop from "./components/Layouts/GoTop";
import AosAnimation from "./components/Layouts/AosAnimation";
import Proposta from "./pages/proposta/proposta";
import ContactUs from "./pages/contact-us/contact-us";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/proposta" element={<Proposta />} />
      </Routes>
      <AosAnimation />
      <GoTop />
    </Router>
  );
}

export default App;
