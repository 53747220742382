/* eslint-disable jsx-a11y/img-redundant-alt */
export default function ContactForm() {
  return (
    <>
      <div className="contact-section ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="contact-image">
                <img
                  src="/images/contact.png"
                  alt="image"
                  width={475}
                  height={436}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="contact-form">
                <iframe
                  title="Teste"
                  width="600"
                  height="500"
                  src="https://portal.eazydev.com.br/forms/wtl/8d243c2220b2f6c1e51bef76ce2bb4d4"
                  frameBorder="0"
                  sandbox="allow-top-navigation allow-forms allow-scripts allow-same-origin allow-popups"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
