import PageBanner from "../../components/Common/PageBanner";
import ContactForm from "../../components/Contact/ContactForm";
import ContactInfo from "../../components/Contact/ContactInfo";
import Footer from "../../components/Layouts/Footer";
import Navbar from "../../components/Layouts/Navbar";
import { useTranslation } from "react-i18next";
import "../../i18n";

export default function ContactUs() {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />

      <PageBanner
        pageTitle={t("pageTitle_contact_us")}
        breadcrumbTextOne={t("breadcrumbTextOne_contact_us")}
        breadcrumbTextTwo={t("breadcrumbTextTwo_contact_us")}
        breadcrumbUrl="/"
        bgImage="images/page-title-bg.jpg"
      />

      <ContactInfo />

      <ContactForm />

      <Footer />
    </>
  );
}
