/* eslint-disable jsx-a11y/img-redundant-alt */
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useTranslation } from 'react-i18next';
import '../../i18n';

export default function Testimonial() {

  const { t } = useTranslation();

  const testimonials = [
    {
      name: "Marcos Antonio",
      image: "/images/clients/client1.jpg",
      office: t('client1_office_testimonial'),
      testimonial: t('client1_testimonial')
    },
    {
      name: "Marcela Dias",
      image: "/images/clients/client2.jpg",
      office: t('client2_office_testimonial'),
      testimonial: t('client2_testimonial')
    },
    {
      name: "André Garcia",
      image: "/images/clients/client3.jpg",
      office: t('client3_office_testimonial'),
      testimonial: t('client3_testimonial')
    }
  ];

  return (
    <>
      <div className="testimonial-section ptb-100">
        <div className="container">
          <div className="section-title">
            <span>{t('title_testimonial')}</span>
            <h2>{t('reports_testimonial')}</h2>
          </div>

          <Swiper
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="testimonial-slider"
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <div className="testimonial-single-item">
                  <div className="testimonial-image">
                    <img
                      src={testimonial.image}
                      alt="image"
                      width={150}
                      height={150}
                    />
                  </div>

                  <div className="testimonial-info">
                    <h3>{testimonial.name}</h3>
                    <span>{testimonial.office}</span>
                  </div>

                  <div className="testimonial-content">
                    <div className="icon">
                      <i className="flaticon-quote"></i>
                    </div>
                    <p>{testimonial.testimonial}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

        </div>

        {/* Shape Images */}
        <div className="testimonial-shape">
          <img
            src="/images/shape/testimonial-shape-1.png"
            alt="main-image"
            width={830}
            height={590}
          />
        </div>
        <div className="testimonial-shape-img1">
          <img
            src="/images/shape/testimonial-shape-2.png"
            alt="main-image"
            width={414}
            height={172}
          />
        </div>
        <div className="testimonial-shape-img2">
          <img
            src="/images/shape/testimonial-shape-3.png"
            alt="main-image"
            width={15}
            height={14}
          />
        </div>
      </div>
    </>
  )
}